<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant :changeItem="changeItem" type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="2M"
            label="(기간)개선완료 예정일"
            name="improveCompleteExpectDates"
            v-model="searchParam.improveCompleteExpectDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="IBM_TASK_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmTaskTypeCd"
            label="업무"
            v-model="searchParam.ibmTaskTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="진행단계"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 요청업체 -->
          <c-vendor
            :plantCd="searchParam.plantCd"
            :changeItem="changeItem"
            label="요청업체"
            name="improveRequestDeptCd"
            v-model="searchParam.improveRequestDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 개선담당업체 -->
            <!-- :disabled="vendorDisable" -->
          <c-vendor
            :plantCd="searchParam.plantCd"
            :changeItem="changeItem"
            label="개선담당업체"
            name="actionDeptCd"
            v-model="searchParam.actionDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :isFlag="true"
            falseLabel="All"
            label="지연된 업무 확인"
            name="overFlag"
            v-model="searchParam.overFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="개선 목록"
      tableId="impr01"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn 
            :disabled="!grid.data || grid.data.length === 0"
            label="개선결과보고서" 
            icon="print"
            @btnClicked="imprResultPrint" />
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addEtcImpr" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template>
          <span>
            {{props.row.improveCompleteExpectDate}}
            <q-badge v-if="props.row.completeDateOver > 0" color="negative" label="지연" class="blinking" />
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'impr',
  data() {
    return {
      searchParam: {
        plantCd: null,
        improveCompleteExpectDates: [],
        ibmTaskTypeCd: null,
        stepCd: null,
        improveRequestDeptCd: '',
        actionUserId: '',
        overFlag: 'N',
        companyCd: '',
        actionDeptCd: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장',
            align: 'center',
            style: 'width:170px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            label: '요청업체',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '개선대책',
            align: 'left',
            style: 'width:200px',
            type: 'html',
            sortable: true,
          },
          {
            name: 'improveCompleteExpectDate',
            field: 'improveCompleteExpectDate',
            label: '개선완료 예정일',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '개선담당업체',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          // {
          //   name: 'improveCompleteDate',
          //   field: 'improveCompleteDate',
          //   label: '개선완료일',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '개선조치내역',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '개선구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
        ],
        data: [],
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
      listUrl: '',
      printUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    vendorDisable() {
      return this.$store.state.user.user.innerFlag === 'N'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.$store.getters.user.innerFlag === 'N') {
        this.searchParam.actionDeptCd = this.$store.getters.user.deptCd
      }
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      this.printUrl = selectConfig.sop.ibm.improve.print.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    addEtcImpr() {
      this.linkClick()
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (!row || row.ibmClassCd == 'IC00000001') {// 즉시조치
        this.popupOptions.title = '개선 상세'; // 개선 상세
        this.popupOptions.isFull = true;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.isFull = false;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    imprResultPrint() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request(
        _result => {
          let fileOrgNm = '개선 결과 보고서_' + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>
